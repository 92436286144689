/// <reference path="../Libs/tempusdominus-bootstrap.d.ts" />
import * as moment from "moment-timezone";
import { Helpers } from "../Utilities/Helpers";
import { CustomControlConstants } from "./CustomControlConstants";

export class DatePicker {

    // Format.
    private dateFormat = CustomControlConstants.DatePicker.dateFormat;
    private dateTimeFormat = CustomControlConstants.DatePicker.dateTimeFormat;

    // CSS.
    static datePickerCss = CustomControlConstants.DatePicker.datePickerCss;
    static timeCss = CustomControlConstants.DatePicker.timeCss;
    static todayCss = CustomControlConstants.DatePicker.todayCss;
    static inputCss = CustomControlConstants.DatePicker.inputCss;

    // Data.
    static notAllowFuture = "not-allow-future";

    // Default settings.
    private timeZone = "US/Pacific";

    // Elements
    //  private datePicker: TempusDominusBootstrap4.Datetimepicker;
    private $input: JQuery<HTMLElement>;

    constructor(private $wrapper: JQuery, private options?: TempusDominusBootstrap4.SetOptions) {
        moment.tz(this.timeZone);
        this.$input = $wrapper.find("> input");
        this.initialize();
    }

    private initialize() {
        if (this.options == null) {
            this.options = {
                defaultDate: "",
                allowInputToggle: true,
                keepInvalid: true,
                timeZone: this.timeZone,
                keepOpen: false,

                // Don't set this to "true" since it will set the date value to current date if date is null.
                useCurrent: false,
                keyBinds: {
                    up: null,
                    down: null,
                    left: null,
                    right: null
                },
                // Forcing date picker (moment) to validate date-time format strictly.
                useStrict: true,
                format: this.hasTimePicker() ? this.dateTimeFormat : this.dateFormat
            };

            this.$wrapper.datetimepicker(this.options);

            if (this.hasTimePicker()) {
                this.$input.inputmask({ mask: "99/99/9999 99:99" });
            } else {
                this.$input.inputmask({ mask: "99/99/9999" });
            }

            if (this.isReadonly())
                this.disable();

            this.setMaxDate();
            this.bindEvents();
        }
    }

    /**Func from datepicker plugin.
     * Destroys the widget and removes all attached event listeners.
     */
    destroy() {
        this.$wrapper.datetimepicker("destroy");
    }

    /** Func from datepicker plugin.
     *  Shows or hides the widget.
     *  Emits
     *      hide.datetimepicker - if the widget is hidden after the toggle call
     *      show.datetimepicker - if the widget is show after the toggle call
     *      change.datetimepicker - if the widget is opened for the first time and the input element is empty and options.useCurrent != false
     */
    toggle() {
        this.$wrapper.datetimepicker("toggle");
    }

    /** Func from datepicker plugin: Shows the widget.
     *  Emits
            show.datetimepicker - if the widget was hidden before that call
            change.datetimepicker - if the widget is opened for the first time and the useCurrent is set to true or to a granularity value 
                and the input element the component is attached to has an empty value
     *
     */
    show() {
        this.$wrapper.datetimepicker("show");
    }

    /**
     * Func from datepicker plugin: Hides the widget
     * Emits hide.datetimepicker - if the widget was visible before that call
     */
    hide() {
        this.$wrapper.datetimepicker("hide");
    }

    /**
     * Func from datepicker plugin: Disables the input element, the component is attached to, by adding a disabled="true" attribute to it.
     * If the widget was visible before that call it is hidden.
     */
    disable() {
        this.$wrapper.datetimepicker("disable");
        this.$input.prop("disabled", false);
        this.$input.prop("readonly", true);
    }

    /**Func from datepicker plugin: Enables the input element, the component is attached to, by removing disabled attribute from it.
     *
     */
    enable() {
        this.$wrapper.datetimepicker("enable");
        this.$input.prop("disabled", false);
        this.$input.prop("readonly", false);
    }

    /**Func from datepicker plugin:Clears the date picker by setting the value to null
     *
     */
    clear() {
        DatePicker.clear(this.$wrapper);
    }

    getDate(): moment.Moment  {
        return DatePicker.getDate(this.$wrapper);
    }

    setDate(date: string | moment.Moment | Date) {
        DatePicker.setDate(this.$wrapper, date);
    }

    private setMaxDate() {
        if (this.allowFuture())
            return;

        const now = this.getNow();
        const maxDate = now.hour(23).minute(59).second(59) as moment.Moment;

        // Set max date.
        //   this.datePicker.maxDate(maxDate);

        // Disable the next date on the calendar, it is for timezones where time is before UTC.
        const nextDate = maxDate.add("second", 1).hour(23).minute(59).second(59) as moment.Moment;
        // this.datePicker.disabledDates([nextDate]);
    }

    private getNow(): moment.Moment {
        const now = moment.tz(this.timeZone) as moment.Moment;
        return now;
    }

    private bindEvents() {
        const self = this;
        self.$wrapper.on("change.datetimepicker", (e) => {
            self.$wrapper.datetimepicker("hide");
        });
    }

    private allowFuture(): boolean {
        const data = this.$wrapper.data(DatePicker.notAllowFuture);
        if (data)
            return false;

        return true;
    }

    private hasTimePicker(): boolean {
        return this.$wrapper.hasClass(DatePicker.timeCss);
    }

    private isReadonly(): boolean {
        return this.$wrapper.is("[readonly]");
    }

    static scanPage() {
        $(`div.${DatePicker.datePickerCss}`)
            .each((index, element) => {
                const picker = new DatePicker($(element) as JQuery);
            });
    }

    static getWrapper($item: JQuery) {
        if (!Helpers.exists($item)) return null;

        if ($item.hasClass(DatePicker.datePickerCss)) return $item;

        return $item.closest(`div.${DatePicker.datePickerCss}`);
    }

    static clear($item: JQuery) {
        const $wrapper = DatePicker.getWrapper($item);
        if (!Helpers.exists($wrapper)) return;
        $wrapper.datetimepicker("clear");
        $wrapper.find(`input${DatePicker.inputCss}`).blur();
    }

    static getDate($item: JQuery): moment.Moment {
        const $wrapper = DatePicker.getWrapper($item);
        if (!Helpers.exists($wrapper)) return null;
        const value = $wrapper.find(`input.${DatePicker.inputCss}`).val();
        if (Helpers.isNullOrEmpty(value))
            return null;

        const result = $wrapper.datetimepicker("viewDate") as moment.Moment;

        return result;
    }

    static setDate($item: JQuery, date: string | moment.Moment | Date) {
        const $wrapper = DatePicker.getWrapper($item);
        if (!Helpers.exists($wrapper)) return;
        $wrapper.datetimepicker("viewDate", date);
    }
}