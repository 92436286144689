﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { DataGridConstants } from "../../../Shared/CustomControls/DataGrids/DataGridConstants";
import { Helpers } from "../../../Shared/Utilities/Helpers";
import { BootstrapModal } from "../../../Shared/CustomControls/BootstrapModal";

export class SystemConfiguration {

    /**
    * Create a new instance of a page.
    */
    constructor() {
       
    }
}