﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";
import { BootstrapModal } from "../../../Shared/CustomControls/BootstrapModal";
import { DataGridCommand } from "../../../Shared/CustomControls/DataGrids/DataGridCommand";

export class AppApiLog {

    private dataGrid: DataGrid;
    private $grid: JQuery;
    private editModal: BootstrapModal;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");

        this.bindSearchPage();
        this.bindModal();
    }

    /**
     * Set up for the Target Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;

        // Data table.
        self.dataGrid = new DataGrid(self.$grid);
        self.dataGrid.onRowView(
            (command: DataGridCommand) => {
                const url = command.url();
                this.editModal.loadAndOpen(url);
            });
    }

    /**
    * Set up modal for viewing details of a log.
    */
    private bindModal() {
        const self = this;
        self.editModal = new BootstrapModal($("#editModal"));
    }
}