import { Helpers } from "../Utilities/Helpers";

export class Select2Control {

    private control: any;

    constructor(private $element: JQuery,
        private options?: Select2.Options) {
        if (!Helpers.exists($element))
            throw new Error("Element does not exist.");

        this.initialize();
    }

    private initialize() {
        if (Helpers.isNull(this.options)) {
            this.options = {

            };
        }

        this.control = this.$element.select2(this.options);
    }
}