import { DatePicker } from "../CustomControls/DatePicker";
import { FormControl } from "../CustomControls/FormControl";
import { BootstrapModal } from "../CustomControls/BootstrapModal";
import { Helpers } from "../Utilities/Helpers";

/**
 * Handle client side common behaviors for all pages.
 */
export class BasePage {

    protected globalYesNoModal: BootstrapModal;
    protected globalErrorModal: BootstrapModal;
    protected globalInfoModal: BootstrapModal;

    /**
     * Create a new instance of a page.
     */
    constructor() {
        this.scanControls($("body"));
        this.initializeGlobalModals();
        this.scanPageTitle();
    }

    /**
    * Set up for global modals.
    */
    protected initializeGlobalModals() {
        this.globalErrorModal = new BootstrapModal($("#globalErrorModal"));
        this.globalYesNoModal = new BootstrapModal($("#globalYesNoModal"));
        this.globalInfoModal = new BootstrapModal($("#globalInfoModal"));
    }

    /**
    * Open the global confirmation modal.
    * @param message: Message to set to the modal's body.
    * @param onYes: Handler to be triggered when users click the Yes button.
    * @param onNo: Handler to be triggered when users click the No button.
    */
    showConfirmationModal(message: string, onYes: () => any, onNo?: () => any) {
        const self = this;
        self.globalYesNoModal.setBodyContent(message);
        self.globalYesNoModal.onAccept((e) => {
            self.globalYesNoModal.close();
            if (onYes)
                onYes();
        });
        self.globalYesNoModal.onCancel((e) => {
            self.globalYesNoModal.close();
            if (onNo)
                onNo();
        });
        self.globalYesNoModal.open();
    }

    /**
     * Open the global Info modal.
     * @param message: Message to set to the modal's body.
     */
    showInfoModal(message: string) {
        const self = this;
        this.globalInfoModal.setBodyContent(message);
        this.globalInfoModal.open();
        this.globalInfoModal.onCancel((e) => { self.globalInfoModal.close(); });
    }

    /**
     * Open the global Error modal.
     * @param error: Message to set to the modal's body.
     */
    showErrorModal(error: string) {
        const self = this;
        this.globalErrorModal.setBodyContent(error);
        this.globalErrorModal.open();
        this.globalErrorModal.onCancel((e) => { self.globalErrorModal.close(); });
    }

    /**
     * Scan custom controls and set up them.
     */
    scanControls($form: JQuery) {
        DatePicker.scanPage();

        const form = new FormControl($form);
        form.scanCustomControls();
    }

    /**
    * Scan page title based on the active left navigation.
    */
    scanPageTitle() {
        const pageTitle = $(".page-header h1");
        const activeItem = $("a.nav-link.active");
        if (Helpers.exists(pageTitle) && Helpers.exists(activeItem)) {
            if (Helpers.isNullOrEmpty(pageTitle.html().trim())) {
                pageTitle.html(activeItem.html().trim());
            }
        }
    }
}