﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class UserLoginHistory {

    private dataGrid: DataGrid;
    private $grid: JQuery;
    private $forAdminCheckbox: JQuery;
    private $forAppCheckbox: JQuery;
    private $clientNameTextbox: JQuery;
    private $clientUrlTextbox: JQuery;
    private $hostIdDropdown: JQuery;
    private $siteTypeDropdown: JQuery;
    private $clientTypeDropdown: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");
        this.$forAdminCheckbox = $("#ForAdminSite");
        this.$forAppCheckbox = $("#ForItxApp");
        this.$clientNameTextbox = $("#SearchClientName");
        this.$clientUrlTextbox = $("#SearchClientUrl");
        this.$hostIdDropdown = $("#SearchHostId");
        this.$siteTypeDropdown = $("#SearchSiteType");
        this.$clientTypeDropdown = $("#SearchClientType");

        this.bindSearchPage();
    }

    /**
     * Set up for the Target Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;
        
        // Data table.
        self.dataGrid = new DataGrid(self.$grid);

        // Checkbox.
        self.$forAdminCheckbox.change(() => { self.bindCriteria(); });
        self.$forAppCheckbox.change(() => { self.bindCriteria(); });

        self.bindCriteria();
    }

    /**
     * Set up for search criteria fields.
     */
    private bindCriteria() {
        const isForAdmin = this.$forAdminCheckbox.is(":checked");
        const isForApp = this.$forAppCheckbox.is(":checked");

        this.bindElement(this.$forAdminCheckbox, !isForApp);
        this.bindElement(this.$forAppCheckbox, !isForAdmin);
        this.bindElement(this.$clientNameTextbox, !isForAdmin && !isForApp);
        this.bindElement(this.$clientUrlTextbox, !isForAdmin && !isForApp);
        this.bindElement(this.$hostIdDropdown, !isForAdmin && !isForApp);
        this.bindElement(this.$siteTypeDropdown, !isForAdmin && !isForApp);
        this.bindElement(this.$clientTypeDropdown, !isForAdmin && !isForApp);
    }

    /**
     * Enable/Disable an element.
     * @param $element: Element to enable/disable.
     * @param isEnabled: True if to enable.
     */
    private bindElement($element: JQuery, isEnabled: boolean) {
        if (isEnabled) {
            Helpers.enable($element);
            return;
        }

        if ($element.is("select")) {
            Helpers.resetDropDown($element);

            const select2Id = Helpers.getData<string>($element, "select2-id", "");
            if (!Helpers.isNullOrEmpty(select2Id)) {
                $element.trigger("change");
            }
        }
        else
            $element.val("");
        Helpers.disable($element);
        
    }
}