﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class Client {

    // Client Search page.
    private clientDataGrid: DataGrid;
    private $clientSearchGrid: JQuery;
    private $searchButton: JQuery;
    private $searchHostDropdown: JQuery;

    // Client Edit page.
    private $hostSelectionDiv: JQuery;
    private $isForMultiHostCheckbox: JQuery;
    private $form: JQuery;
    private $plainTextBox: JQuery;
    private $encryptedTextBox: JQuery;
    private $isEditingSecretHidden: JQuery;
    private $parentClientDropdown: JQuery;
    private $loggedInHostDropdown: JQuery;
    private $defaultHostDropdown: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor(private hostEditUrl?: string) {

        // Get controls for the Client search page.
        this.$clientSearchGrid = $("#gridData");
        this.$searchButton = $("#btnSearch");
        this.$searchHostDropdown = $("#SearchHostId");

        // Get controls for the Client edit page.
        this.$isForMultiHostCheckbox = $("#IsForMultiHost");
        this.$hostSelectionDiv = $("div.login-host-selection");
        this.$form = $("#editClientForm");
        this.$plainTextBox = $("#PlainClientSecret");
        this.$encryptedTextBox = $("#EncryptedClientSecret");
        this.$isEditingSecretHidden = $("#IsEditingSecret");
        this.$loggedInHostDropdown = $("#SelectedLoggedInHostIds");
        this.$parentClientDropdown = $("#ClientParentId");
        this.$defaultHostDropdown = $("#DefaultHostId");

        // Bind form events.
        this.bindSearchPage();
        this.bindEditPage();
    }

    /**
     * Set up for the Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$clientSearchGrid))
            return;

        // Data table.
        self.clientDataGrid = new DataGrid(self.$clientSearchGrid);
        self.clientDataGrid.bindDefaultRowDelete(() => { Helpers.triggerClick(self.$searchButton); },
            () => "Are you sure you want to delete this client?");
    }

    /**
    * Set up for the Edit page.
    */
    private bindEditPage() {
        const self = this;

        if (Helpers.exists(self.$clientSearchGrid))
            return;

        // Initialize all tooltips.
        self.$form.find("[data-toggle='popover']").popover({ html: true });

        // Set up for Host selection section.
        self.bindLoginHostSelection();

        // Client secret section.
        self.bindSecretSection();
    }

    private bindSecretSection() {
        const self = this;
        self.triggerSecretTextBox();

        const $btnEditSecret = $("#btnEditSecret");
        const $btnCancelSecret = $("#btnCancelEditSecret");
        if (!Helpers.exists($btnEditSecret))
            return;

        $btnEditSecret.click(() => {
            self.$plainTextBox.val("");
            self.$isEditingSecretHidden.val("true");
            self.triggerSecretTextBox();
        });

        $btnCancelSecret.click(() => {
            self.$plainTextBox.val(self.$encryptedTextBox.val());
            self.$isEditingSecretHidden.val("false");
            self.triggerSecretTextBox();
        });
    }

    private bindLoginHostSelection() {

        const self = this;
        self.$isForMultiHostCheckbox.change(() => { self.triggerHostSelection(); });

        self.triggerHostSelection();
    }

    private triggerSecretTextBox() {
        const $plain = $(".plain-secret");
        const $encrypted = $(".encrypted-secret");
        const isNew = !Helpers.exists(this.$isEditingSecretHidden);
    
        if (isNew) {
            Helpers.show($plain);
            Helpers.hide($encrypted);
        } else {
            if (this.isEditingSecret()) {
                Helpers.show($plain);
                Helpers.hide($encrypted);
            } else {
                Helpers.hide($plain);
                Helpers.show($encrypted);
            }
        }
    }

    private isEditingSecret(): boolean {
        if (!Helpers.exists(this.$isEditingSecretHidden))
            return false;
        const value = this.$isEditingSecretHidden.val().toString().toLowerCase();
        if (value === "true") return true;

        return false;
    }

    private triggerHostSelection() {
        const isChecked = this.$isForMultiHostCheckbox.is(":checked");
        if (isChecked)
            Helpers.show(this.$hostSelectionDiv);
        else
            Helpers.hide(this.$hostSelectionDiv);
    }
}