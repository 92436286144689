﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class Host {

    private dataGrid: DataGrid;
    private $grid: JQuery;

    private $UserLockoutEnabled: JQuery;
    private $LockoutInMinute: JQuery;
    private $PasswordComplexLevel: JQuery;
    private $searchButton: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");
        this.$UserLockoutEnabled = $("#UserLockoutEnabled");
        this.$LockoutInMinute = $("#LockoutInMinute");
        this.$PasswordComplexLevel = $("#PasswordComplexLevel");
        this.$searchButton = $("#btnSearch");

        this.bindSearchPage();
        this.bindEditPage();
    }

    /**
     * Set up for the Target Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;

        // Data table.
        self.dataGrid = new DataGrid(self.$grid);
        self.dataGrid.bindDefaultRowDelete(() => { Helpers.triggerClick(self.$searchButton); }, () => "Are you sure you want to delete this host?");
    }

    private bindEditPage() {
        const self = this;

        if (Helpers.exists(self.$grid))
            return;

        $("[data-toggle='popover']").popover({ html: true });

        self.$UserLockoutEnabled.change(() => {
            self.bindUserLockout();
        });

        self.$PasswordComplexLevel.change(() => {
            self.bindComplexPassword();
        });

        self.bindUserLockout();
        self.bindComplexPassword();
    }

    private bindUserLockout() {
        const self = this;
        const isChecked = self.$UserLockoutEnabled.is(":checked");
        if (isChecked) {
            Helpers.enable(self.$LockoutInMinute);
            return;
        }

        Helpers.disable(self.$LockoutInMinute);
    }

    private bindComplexPassword() {
        const self = this;
        const level = Number(self.$PasswordComplexLevel.val());
        $(".password-complex-note").each((index, element) => {
            const $element = $(element);
            const elementLevel = Number($element.data("level"));
            if (elementLevel === level)
                Helpers.show($element);
            else
                Helpers.hide($element);
        });
    }
}