﻿import { Helpers } from "../Shared/Utilities/Helpers";

/**
 * Control client behaviors for login, reset pass, and confirm pass pages.
 */
export class Account {
    constructor() {
        this.setupRememberWarning();
        this.setupEmail();
    }

    /**
     * Set up email validation for masked.
     */
    setupEmail(): void {
        const $email = $("input#Email"), masked = $email.data("masked");
        if ($email && $email.length && masked && masked === $email.val()) {
            console.log("changing validator settings");
            const settings = $.data($('form')[0], 'validator').settings;
            settings.ignore = ":hidden,#Email";
            $email.change(() => {
                console.log("reverting validator settings");
                settings.ignore = ":hidden";
            });
        }
    }

    /**
     * Perform warning show/hide.
     * @param $username
     * @param $warning
     */
    rememberWarning($username: JQuery, $warning: JQuery): void {
        if ($username.prop("checked")) {
            $warning.removeClass("d-none");
        } else {
            $warning.addClass("d-none");
        }
    }

    /**
     * Set up username warning if available..
     */
    setupRememberWarning(): void {
        const $username = $("input#RememberUsername"), $warning = $("#RememberUsernameWarning");
        if ($username && $username.length && $warning && $warning.length) {
            $username.change(() => {
                this.rememberWarning($username, $warning);
            });
            this.rememberWarning($username, $warning);
        }
    }

    /**
     * Redirect to the login page.
     */
    goToSignIn() {
        const signInUrl = $(document).find("meta[http-equiv=refresh]").data("url");
        window.location.href = signInUrl;
    }

    /**
     * Redirect to logout page.
     */
    redirectToSignOutUrl() {
        const redirectUrl = $("#postLogoutRedirectUrl");
        if (redirectUrl) {
            window.location.href = redirectUrl.prop("href");
        }
    }
}