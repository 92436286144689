import { DataGridConstants } from "./DataGridConstants";
import { BasePage } from "../../Pages/BasePage";
import { Helpers } from "../../Utilities/Helpers";

/**
 * Parse settings/data and perform actions related to data grid command.
 */
export class DataGridCommand {
    private basePage: BasePage;

    /**
     * Create a new instance of data grid command.
     * @param $element: HTML element of the command.
     * @param $parent: HTML element of the parent of the command if any.
     */
    constructor(private $element: JQuery, private $parent?: JQuery) {
        this.basePage = new BasePage();
    }

    /**
     * Get the value of 'data-id' attribute.
     */
    id(): string {
        return this.$element.data(DataGridConstants.Data.id);
    }

    /**
     *  Get the value of 'data-url' attribute.
     */
    url(): string {
        return this.$element.data(DataGridConstants.Data.url);
    }

    /**
     * Get the value of 'data-command-name' attribute.
     */
    commandName(): string {
        return this.$element.data(DataGridConstants.Data.commandName);
    }

    /**
     * Get the HTML element of the current command.
     */
    element(): JQuery {
        return this.$element;
    }

    /**
     * Get the parent HTML element of the current command.
     */
    parent(): JQuery {
        return this.$parent;
    }

    /**
     * Bind click event for the current command.
     * @param handler: callback handler to be triggered when the current command is clicked.
     */
    onClick(handler: (command: DataGridCommand) => any) {
        this.$element.off(DataGridConstants.Events.click);
        if (Helpers.isNull(handler))
            return;

        const self = this;
        self.$element.on(DataGridConstants.Events.click, () => handler(self));
    }

    /**
      * Perform ajax POST for a command.
      * @param onSuccess: Callback when success.
      */
    ajaxPost(onSuccess?: () => any) {
        const commandUrl = this.url();
        const self = this;
        $.ajax(
            {
                url: commandUrl,
                method: "POST",
                success(result) {
                    if (result) {
                        if (result.isSuccess) {
                            if (onSuccess)
                                onSuccess();

                            return;
                        }

                        if (result.errors)
                            self.basePage.showErrorModal(result.errors);
                    }
                },
                error() {
                    self.basePage.showErrorModal(DataGridConstants.Messages.unableToPerformCommand);
                }
            });
    }

    /**
 * Perform ajax GET for a command.
 * @param onSuccess: Callback when success.
 */
    performAjaxGet(onSuccess?: () => any) {
        const commandUrl = this.url();
        const self = this;
        $.ajax(
            {
                url: commandUrl,
                method: "GET",
                success(result) {
                    if (result) {
                        if (result.isSuccess) {
                            if (onSuccess)
                                onSuccess();

                            return result;
                        }

                        if (result.errors)
                            self.basePage.showErrorModal(result.errors);
                    }
                    return result;
                },
                error() {
                    self.basePage.showErrorModal(DataGridConstants.Messages.unableToPerformCommand);
                }
            });
    }
}