import "bootstrap";
import { ModalOption } from "bootstrap";
import { ValidationHelpers } from "../Utilities/ValidationHelpers";
import { Helpers } from "../Utilities/Helpers";
import { BasePage } from "../Pages/BasePage";

/** Control behaviors of a bootstrap modal. */
export class BootstrapModal {

    private static modalCommand = "modal-command";
    static acceptCommand = "accept";
    static cancelCommand = "cancel";
    static submitCommand = "submit";
    static submitUrl = "submit-url";

    // Events which can set by public calls.
    private onOpeningFunc: (e: JQueryEventObject) => any;
    private onOpenedFunc: (e: JQueryEventObject) => any;
    private onClosingFunc: (e: JQueryEventObject) => any;
    private onClosedFunc: (e: JQueryEventObject) => any;
    private onLoadedFunc: (e: JQueryEventObject) => any;

    /**
     * Create a new instance of Bootstrap modal.
     * @param $modal: HTML element to build modal.
     * @param options: Settings for modal.
     */
    constructor(private $modal: JQuery,
                private options?: IBootstrapModalOption) {
        this.initialize();
    }

    /**
     * Set up for modal.
     */
    private initialize() {
        const self = this;

        if (!self.options) {

            // Default options.
            self.options = {
                backdrop: "static",
                keyboard: false,
                show: false
            };
        }

        self.$modal.modal(self.options as ModalOption);
    }

    /**
     * Get the form element inside the modal body if any.
     */
    getForm(): JQuery {
        return this.getContainer().find("form");
    }

    /**
     *  Bind events for modal.
     * @param eventName: Name of event.
     * @param callback: Handler to be triggered when the event occurs.
     */
    private bindEvent(eventName: string, callback: (e: JQueryEventObject) => any) {
        if (callback === null) {
            this.$modal.off(eventName);
            return;
        }
        this.$modal.on(eventName, callback);
    }

    /**
    * Manually toggles a modal.
    * Returns to the caller before the modal has actually been shown or hidden (i.e. before the shown.bs.modal or hidden.bs.modal event occurs).
    */
    toggle() {
        this.$modal.modal("toggle");
    }

    /**
     * Checks if the modal is open.
     */
    isOpen(): boolean {
        const isShown = (this.$modal.data("bs.modal") || { isShown: false }).isShown;
        return isShown;
    }

    /**
   * Manually opens a modal. Returns to the caller before the modal has actually been shown (i.e. before the shown.bs.modal event occurs).
   */
    open() {
        const self = this;

        if (self.isOpen())
            return;

        // NOTE: Bootstrap does not support opening multiple modals. It will add padding-right to the body to replace the page scrollbar every time a modal opened.
        // This is just a workaround for the issue. Before opening a new modal, check if there is another opening modal. 
        // If there is, wait for some time to let the previous modal close completely.
        if ($("body.modal-open").length > 0) {
            setTimeout(() => {
                    self.$modal.modal("show");
                },
                500);

            return;
        }

        self.$modal.modal("show");
    }

    /**
    * This event fires immediately when the show instance method is called.
    * If caused by a click, the clicked element is available as the relatedTarget property of the event.
    * @param callback
    */
    onOpening(callback: (e: JQueryEventObject) => any): void {
        this.onOpeningFunc = callback;
    }

    /**
     * This event is fired when the modal has been made visible to the user (will wait for CSS transitions to complete).
     * If caused by a click, the clicked element is available as the relatedTarget property of the event.
     * @param callback
     */
    onOpened(callback: (e: JQueryEventObject) => any): void {
        this.onOpenedFunc = callback;
    }

    /**
     * This event is fired immediately when the hide instance method has been called.
     * @param callback
     */
    onClosing(callback: (e: JQueryEventObject) => any): void {
        this.onClosingFunc = callback;
    }

    /**
     * This event is fired when the modal has finished being hidden from the user (will wait for CSS transitions to complete).
     * @param callback
     */
    onClosed(callback: (e: JQueryEventObject) => any): void {
        this.onClosedFunc = callback;
    }

    /**
     * This event is fired when the modal has loaded content using the remote option.
     * @param callback
     */
    onLoaded(callback: (e: JQueryEventObject) => any): void {
        this.onLoadedFunc = callback;
    }

    /**
     * Manually hides a modal. Returns to the caller before the modal has actually been hidden (i.e. before the hidden.bs.modal event occurs).
     */
    close() {
        this.$modal.modal("hide");
    }

    /**
     * Readjusts the modal's positioning to counter a scrollbar in case one should appear, which would make the modal jump to the left.
     * Only needed when the height of the modal changes while it is open.
     */
    handleUpdate() {
        this.$modal.modal("handleUpdate");
    }

    /**
   * Bind click event for an element, which has attribute [data-modal-command="commandName"].
   * When it is clicked, trigger callback function.
   * If the callback function is null, it will unbind the click event.
   * @param callback: function to be called back.
   */
    onCommand(commandName: string, callback?: (e?: JQueryEventObject) => any): void {
        const clickNamespace = "click";
        const $button = this.getCommandButton(commandName);
        if ($button.length === 0)
            return;

        $button.off(clickNamespace);
        if (callback === null) {
            return;
        }

        $button.on(clickNamespace, callback);
    }

    /**
     * Bind default submit event for modal.
     * @param onSuccess: Handler to be triggered when the submission is success.
     * @param onBeforeSubmit: Handler to be triggered when the save button is clicked and before the form is submitted.
     */
    bindDefaultSubmitEvent(onSuccess?: () => any, onBeforeSubmit?:()=>any) {
        const self = this;
        const submitButton = self.getCommandButton(BootstrapModal.submitCommand);
        const submitUrl = submitButton.data(BootstrapModal.submitUrl);
        if (submitButton.length === 0 || submitUrl === null)
            return;

        this.onCommand(BootstrapModal.submitCommand, (e) => {
            if (!Helpers.isNull(onBeforeSubmit))
                onBeforeSubmit();
            self.submitForm(submitUrl, onSuccess);
        });
    }

    /**
     * Submit a form if any.
     * @param submitUrl: URL to submit.
     * @param onSuccess: callback function on success.
     */
    submitForm(submitUrl: string, onSuccess?: () => any) {
        const self = this;
        const $form = self.getForm();
        if ($form.length === 0)
            return;

        $form.off("submit").on("submit", (e) => {
            e.preventDefault();

            $form.validate();
            const formData = $.param($form.serializeArray());
            $.ajax(
                {
                    url: submitUrl,
                    type: "POST",
                    data: formData,
                    success(result) {
                        self.setBodyContent(result);
                        if (!ValidationHelpers.hasError(self.getContainer())) {
                            if (onSuccess)
                                onSuccess();
                        }
                    },
                    error() {
                        ValidationHelpers.setValidationSummaryErrors($form, ["Cannot submit form."]);
                    }
                });
        });

        $form.submit();
    }

    /**
     * Bind event when users click on a accept command.
     * @param callback: Handler to be triggered when the command is clicked.
     */
    onAccept(callback?: (e?: JQueryEventObject) => any): void {
        this.onCommand(BootstrapModal.acceptCommand, callback);
    }

    /**
     * Bind event when users click on a cancel command.
     * @param callback: Handler to be triggered when the command is clicked.
     */
    onCancel(callback?: (e?: JQueryEventObject) => any): void {
        this.onCommand(BootstrapModal.cancelCommand, callback);
    }

    /**
    * Get button on the modal by command name.
    * @param commandName
    */
    getCommandButton(commandName: string): JQuery {
        const $button = this.getContainer().find(`[data-modal-command="${commandName}"]`);
        return $button;
    }

    /**
     * Return the jquery object of the modal container.
     */
    getContainer(): JQuery {
        return this.$modal;
    }

    /**
     * Return the jquery object of the modal body.
     */
    getModalBody(): JQuery {
        return this.$modal.find("div.modal-body");
    }

    /**
     * Return the jquery object of the modal footer.
     */
    getModalFooter(): JQuery {
        return this.$modal.find("div.modal-footer");
    }

    /**
    * Set html content for the body of the modal.
    * @param htmlContent: The content to be bound to the body.
    */
    setBodyContent(htmlContent: string): void {
        this.getModalBody().html(htmlContent);
        const $form = this.getForm();
        if ($form) {
            ValidationHelpers.registerValidation($form);
            const basePage = new BasePage();
            basePage.scanControls($form);
        }
    }

    /**
     * Send an ajax request to server to load content of the modal and open the modal after load successfully.
     * @param url: URL to send request to server for loading modal's content.
     * @param data: Data to submit for the ajax request.
     * @param callback: Handler to be triggered when the ajax request is done.
     */
    loadAndOpen(url: string,
        data?: JQuery.PlainObject | string,
        callback?: () => any): void {

        const self = this;
        self.loadContent(url, data, callback);
        self.open();
    }

    loadContent(url: string,
                data?: JQuery.PlainObject | string,
                callback?: () => any):void {

        const self = this;
        self.setBodyContent(Helpers.getSpinner());

        $.ajax({
            url: url,
            data: data,
            type: "GET",
            success(result) {
                self.setBodyContent(result);

                if (callback)
                    callback();
            },
            error(jqXhr, textStatus, errorThrown) {
                self.setBodyContent(`<div class="alert alert-danger" role="alert">
                                       <strong>Error occured</strong></br>
                                        <span>Status code: ${jqXhr.statusCode}</span></br>
                                        ${jqXhr.responseText}
                                     </div>`);
            }
        });
    }
}

/**
 * Options to configure bootstrap modal.
 */
interface IBootstrapModalOption extends ModalOption {

}