﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";
import { DataGridCommand } from "../../../Shared/CustomControls/DataGrids/DataGridCommand";
import { BootstrapModal } from "../../../Shared/CustomControls/BootstrapModal";

export class UserAccess {

    private dataGrid: DataGrid;
    private $grid: JQuery;
    private editModal: BootstrapModal;
    private $btnSearch: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");
        this.$btnSearch = $("#btnSearch");

        this.bindSearchPage();
        this.bindModal();
    }

    /**
     * Set up for the Target Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;

        // Data table.
        self.dataGrid = new DataGrid(self.$grid);
        self.dataGrid.onRowEdit((command: DataGridCommand) => { self.edit(command); });
    }

    /**
    * Set up modal for editing token access.
    */
    private bindModal() {
        const self = this;
        const modal = $("#editModal");
        if (modal.length === 0)
            return;

        self.editModal = new BootstrapModal($("#editModal"));
        self.editModal.bindDefaultSubmitEvent(() => {
            self.editModal.close();
            Helpers.triggerClick(self.$btnSearch);
        });
    }

    /**
    * Open modal for editing token access.
    * @param command
    */
    private edit(command: DataGridCommand) {
        const self = this;
        if (self.editModal === null)
            return;

        const url = command.url();
        this.editModal.loadAndOpen(url, null, () => {
            const saveButton = self.editModal.getCommandButton(BootstrapModal.submitCommand);
            const $isAllowedCheckbox = self.editModal.getContainer().find("#IsAllowed");
            if (Helpers.isDisable($isAllowedCheckbox))
                Helpers.disable(saveButton);
        });
    }
}