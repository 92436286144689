﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { DataGridConstants } from "../../../Shared/CustomControls/DataGrids/DataGridConstants";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class Cache {

    private $grid: JQuery;
    private $modal: JQuery;
    private dataGrid: DataGrid;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");

        // Bind form events.
        this.bindGrid();
    }

    /**
     * Set up for grid.
     */
    private bindGrid() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;

        // Data table.
        const grid = new DataGrid(self.$grid);
        grid.bindDefaultRowDelete(null, (row) => {
            const cacheName = row.find("td[data-name='TypeShortName']").html().trimLeft().trimRight();
            return `Are you sure you want to clear cache '<b>${cacheName}</b>'?`;
        });

        grid.onToolbarCommand(DataGridConstants.Commands.delete, (command) => {
            if (!confirm("Are you sure you want to clear all caches?"))
                return;
            command.ajaxPost(() => {
                Helpers.reload();
            });
        });

        self.dataGrid = grid;
    }
}