import { DataGridConstants } from "./DataGridConstants";
import { DataGridCommand } from "./DataGridCommand";
import { Helpers } from "../../Utilities/Helpers";

/**
 * Parse settings/data and perform actions related to data grid column.
 */
export class DataGridColumn {

    /**
     * Create a new instance of data grid column.
     * @param $column: HTML element for the column.
     */
    constructor(private $column: JQuery) {

    }

    /**
     * Get HTML element of the current column.
     */
    element() {
        return this.$column;
    }

    /**
    * Check if a column contains setting data for searchable.
    */
    isSearchable(): boolean {
        return Helpers.getData<boolean>(this.$column, DataGridConstants.Data.enableSearch, false);
    }

    /**
    * Check if a column contains setting data for sortable.
    */
    isSortable(): boolean {
        return Helpers.getData<boolean>(this.$column, DataGridConstants.Data.enableSorting, true);
    }

    /**
    * Check if a column contains truncated text.
    */
    isTruncated(): boolean {
        return this.$column.hasClass(DataGridConstants.Css.truncatedColumn);
    }

    /**
    * Check if a column contains sub row toggle.
    */
    isSubRowToggle(): boolean {
        return this.$column.hasClass(DataGridConstants.Css.subRowToggle);
    }
}