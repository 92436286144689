import { Helpers } from "../Utilities/Helpers";
import { ValidationHelpers } from "../Utilities/ValidationHelpers";
import { CustomControlConstants } from "./CustomControlConstants";
import { DatePicker } from "./DatePicker";
import { Select2Control } from "./Select2Control";

/**
 * Controls common behaviors for form elements.
 */
export class FormControl {

    static readonly dataRemoveQuery = "remove-query";
    static readonly eventClick = "click";

    /**
     * Create a new instance for form control.
     * @param $form
     */
    constructor(private $form: JQuery) {
    }

    /**
     * Scan all custom controls in a page.
     */
    scanCustomControls() {
        this.scanSubmitButtons();
        this.scanResetButtons();
        this.scanNumericTextboxInputMasks();
        this.setUpReadonlyDropdown();
        this.scanBootstrapSwitch();
        this.scanSelect2Controls();
    }

    /**
    * Scan and set up for reset buttons.
    */
    private scanResetButtons(): void {
        const self = this;
        self.$form.find("button[type='reset']")
            .each((index, element) => {
                const $button = $(element);
                self.bindClick($button, () => {
                    // Check if there is a data-remove-query attribute. If yes, remove all querystring in the URL before reloading.
                    // This is used to reset pre-filled filters for search page.
                    const data = $button.data(FormControl.dataRemoveQuery);
                    if (Helpers.isNull(data)) {
                        Helpers.reload();
                        return;
                    }

                    Helpers.reload(true);
                });
            });
    }

    /**
     * Bind click event for an element.
     * @param $element: Element to bind event.
     * @param handler: Handler to be triggered when event occurs.
     */
    private bindClick($element: JQuery, handler: () => any) {
        if (!Helpers.exists($element))
            return;

        $element
            .off(FormControl.eventClick)
            .on(FormControl.eventClick,
                () => {
                    if (!Helpers.isNull(handler))
                        handler();
                });
    }

    /**
    * Scan and set up for submit buttons.
    */
    private scanSubmitButtons() {
        const self = this;
        self.$form.find("button[type='submit']:not([manual-submit])")
            .each((index, element) => {
                const $button = $(element);

                self.bindClick($button, () => {
                    const $modal = $button.closest("modal");
                    let $form: JQuery;
                    if (Helpers.exists($modal))
                        $form = $modal.find("form");
                    else {
                        $form = $button.closest("form");
                        if (!Helpers.exists($form)) {
                            // The submit button is outside of the form, look for form in the whole page 
                            // and inject hidden field for button value if any.
                            $form = $("form");
                            self.injectSubmitValueHiddenField($button, $form);
                        }
                    }

                    if (!ValidationHelpers.isValid($form)) {
                        Helpers.unlockButton($button);
                        return;
                    }

                    Helpers.lockButton($button);
                    $form.submit();
                });
            });

        self.$form.on("keydown", ":input:not(textarea)", event => {

            return event.key !== "Enter";
        });

    }

    /**
     * Inject hidden field for button value. This is used when the submit button is outside of the form.
     * @param $button: Submit button.
     * @param $form: Form to inject hidden.
     */
    private injectSubmitValueHiddenField($button: JQuery, $form: JQuery) {

        // The submit button is outside of a form. 
        // If the submit button has value, insert a hidden field to contain the button value.
        // This is used there are multiple values for the same submit action.
        const buttonValue = $button.val();
        if (!Helpers.isNull(buttonValue)) {
            const buttonName = $button.prop("name");
            const $hidden = $form.find(`[name='${buttonName}']`);
            if (!Helpers.exists($hidden)) {
                $form.append(`<input type="hidden" name="${buttonName}" value="${buttonValue}" />`);
            } else {
                $hidden.val(buttonValue);
            }
        }
    }

    /**
    * Disable all options of readonly dropdowns.
    */
    private setUpReadonlyDropdown() {
        this.$form.find("select[readonly] option:not(:selected)")
            .each((index, element) => {
                $(element).prop("disabled", true);
            });
    }

    /**
    * Scans all inputs of type numeric textbox number and register mask for them.
    */
    private scanNumericTextboxInputMasks(): void {
        const self = this;
        self.$form.find("input[type='text'][data-val-range-max]")
            .each((i, e) => {

                var option: JQueryInputMaskOptions =
                {
                    removeMaskOnSubmit: true,
                    rightAlign: false
                };
                const $element = $(e);
                const maxRange = $element.data("val-range-max").toString();
                const decimalIndex = maxRange.indexOf(".");
                let integerDigit = 0;
                let decimalDigit = 0;
                if (decimalIndex >= 0) {
                    integerDigit = decimalIndex;
                    decimalDigit = maxRange.length - decimalIndex;
                } else {
                    integerDigit = maxRange.length;
                }

                option.regex = self.buildNumericRegex(integerDigit, decimalDigit);
                $element.inputmask(option);
            });
    }

    /**
     * Build numeric regex for input mask.
     * @param integerDigit: Number of digits for integer part.
     * @param decimalDigit: Number of digits for decimal part.
     */
    private buildNumericRegex(integerDigit: number, decimalDigit?: number):string {
        let regex = "";

        if (!Helpers.isNull(integerDigit) && integerDigit > 0) {
            regex += `^[0-9]{1,${integerDigit}}`;
        }

        if (!Helpers.isNull(decimalDigit) && decimalDigit > 0) {
            regex += `(\\.\\d{1,${decimalDigit}})?`;
        }

        if (regex !== "") {
            regex += "$";
        } else {
            regex = "^[0-9]*$";
        }

        return regex;
    }

    /**
     * Scan and build bootstrap switches.
     */
    private scanBootstrapSwitch() {
        const self = this;
        self.$form.find("[data-toggle='switch']").bootstrapSwitch();
    }

    private scanSelect2Controls() {
        const self = this;
        self.$form.find("select[select2]").each((index, element) => {
            const select2 = new Select2Control($(element));
        });
    }

    /**
    *  Clear input of all elements inside a form. This does not clear hidden fields.
    * @param $form
    */
    static clearForm($form: JQuery) {
        if (Helpers.isNull($form))
            return;

        const elements = $form.find("input[type='text'], textarea, select");
        elements.each((index, item) => {
            const $item = $(item);
            if ($item.hasClass(CustomControlConstants.DatePicker.inputCss)) {
                DatePicker.clear($item);
                return;
            }

            if ($item.is("select")) {
                Helpers.resetDropDown($item);
                return;
            }

            $item.val("");
        });
    }

    static validateRequiredDateRange($form: JQuery) {
        if (Helpers.isNull($form))
            return;
    }
}