import { DataGridConstants } from "./DataGridConstants";
import { DataGridCommand } from "./DataGridCommand";
import { Helpers } from "../../Utilities/Helpers";

/**
 * Build toolbar and control behavior of toolbar.
 */
export class DataGridToolbar {
    private commands: Map<string, DataGridCommand>;
    private $toolbar: JQuery;

    /**
     * Create a new instance of a toolbar.
     * @param $container
     */
    constructor($container: JQuery) {
        this.commands = new Map();
        this.build($container);
        this.scanCommands();
    }

    /**
     * Indicate if the toolbar is present for the current data grid.
     */
    hasToolbar(): boolean {
        return Helpers.exists(this.$toolbar);
    }

    /**
     * Bind click event for all buttons in the toolbar.
     * @param name: Command name for button. Should be unique.
     * @param handler: Action to be executed when a command is clicked.
     */
    bindCommand(name: string, handler: (command: DataGridCommand) => any) {
        if (!this.commands.has(name)) {
            return;
        }

        let command = this.commands.get(name);
        command.onClick((e) => handler(e));
    }

    /**
     * Get command of the toolbar.
     * @param name: Command name for button. Should be unique.
     */
    getCommand(name: string): DataGridCommand {
        if (!this.commands.has(name)) {
            return null;
        }

        let command = this.commands.get(name);
        return command;
    }

    /**
     * Get add button if any.
     */
    getAddButton() {
        let command = this.commands.get(DataGridConstants.Commands.add);
        if (Helpers.isNull(command))
            return null;

        return command.element();
    }

    /**
     * Get toolbar HTML element.
     */
    getElement() {
        return this.$toolbar;
    }

    /**
     *  Build toolbar for the current data grid.
     * @param $container: Data grid container.
     */
    private build($container: JQuery) {
        const $toolbar = $container.find(`div.${DataGridConstants.Css.gridToolbar}`);
        if ($container.hasClass(DataGridConstants.Css.noToolbar)) {
            $toolbar.remove();
            return;
        }

        const buttonRow = $container.find(`.${DataGridConstants.Css.buttonRow}`);
        if (!Helpers.exists(buttonRow))
            return;

        $toolbar.find(`.${DataGridConstants.Css.buttons}`).prepend(buttonRow);
        Helpers.show(buttonRow);
        this.$toolbar = $toolbar;
    }

    /**
     * Scan all commands generated for the toolbar and bind click event for them.
     */
    private scanCommands() {
        if (!this.hasToolbar())
            return;

        const self = this;

        self.$toolbar.find(`.${DataGridConstants.Css.gridCommand}`).each((index, button) => {
            const $button = $(button);
            const command = new DataGridCommand($button, self.$toolbar);
            this.commands.set(command.commandName(), command);
            Helpers.show($button);
        });
    }
}