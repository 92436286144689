﻿export class Page {

    static instance: Page;

    page: any;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.scanControls($("body"));
    }

    /**
     * Scan custom controls and set up them.
     */
    scanControls($form: JQuery) {
        $form.find("select[readonly]").each((index, element) => {
            $(element).find("option:not(:selected)").prop("disabled", true);
        });
    }
}