﻿import { BasePage } from "../../Shared/Pages/BasePage";

export class AdminPage extends BasePage {

    static instance: AdminPage;

    page: any;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        super();

        this.setUpPopover();
    }

    private setUpPopover() {
        $("body").on("click", e => {
            $("[data-toggle=popover]").each((index, element) => {
                const $popover = $(element);
                if (!$popover.is(e.target) && $popover.has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
                    $popover.popover("hide");
                }
            });
        });
    }
}