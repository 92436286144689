﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";

/**
 * Handle client side behaviors for the Audit page.
 */
export class PageAudit {

    /**
     * Create an instance of Audit page.
     */
    constructor() {
        this.bindDataGrid();
    }

    /**
     * Initialize for data grid.
     */
    private bindDataGrid() {
        const $grid = $("#gridData");
        if ($grid && $grid.length > 0) {
            const dataGrid = new DataGrid($grid);
        }
    }
}