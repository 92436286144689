export module CustomControlConstants {
    export class DatePicker {
        // Format.
        static readonly dateFormat = "MM/DD/YYYY";
        static readonly dateTimeFormat = "MM/DD/YYYY HH:mm";

        // CSS.
        static readonly datePickerCss = "date-picker";
        static readonly timeCss = "time";
        static readonly todayCss = "today";
        static readonly inputCss = "datetimepicker-input";

        // Data.
        static readonly notAllowFuture = "not-allow-future";
    }
}