import { Helpers } from "../../Utilities/Helpers";
import { DataGridEvents } from "./DataGridEvents";
import { DataGridConstants } from "./DataGridConstants";
import { DataGridColumn } from "./DataGridColumn";
import { DataGridRow } from "./DataGridRow";
import { DataGridCommand } from "./DataGridCommand";
import { DataGridToolbar } from "./DataGridToolbar";
import { BasePage } from "../../Pages/BasePage";

/**
 * Set up datatables and client side behaviors.
 */
export class DataGrid {
    private grid: DataTables.Api;

    private headers: DataGridColumn[];
    private rows: DataGridRow[];
    private toolbar: DataGridToolbar;
    private isInitialized: boolean;
    private basePage: BasePage;
    private rowCommands: Map<string, (command: DataGridCommand) => any>;

    /**
     * Create an instance of data grid.
     * @param $table: Table element to build the data grid.
     * @param options: Settings for the data grid.
     * @param events: Data grid events that needed to bind before creating the data grid instance.
     */
    constructor(private $table: JQuery, private options?: DataTables.Settings, private events?: DataGridEvents) {
        if (Helpers.isNull(this.events))
            this.events = new DataGridEvents();

        this.basePage = new BasePage();
        this.rowCommands = new Map();
        this.initialize();
    }

    /**
     * Get default options for a data grid.
     */
    static defaultOptions(): DataTables.Settings {
        // Creating default settings.
        const order: (number | string)[] = [];
        let options = {
            searching: false,
            lengthChange: false,
            ordering: true,
            paging: false,
            info: false,
            autoWidth: false,
            language: {
                search: DataGridConstants.Messages.searchLabel,
                info: DataGridConstants.Messages.pagingInfo,
                infoEmpty: "",
                paginate: {
                    previous: "<",
                    next: ">",
                    first: "",
                    last: ""
                }
            },
            dom: DataGrid.getLayout(),
            order: order
        };

        return options;
    }

    /**
     * Set up for the data grid.
     */
    private initialize() {
        const hasOptions = !Helpers.isNull(this.options);
        if (!hasOptions) {
            this.options = DataGrid.defaultOptions();
        }

        this.options.pageLength = this.pageLength();
        if (this.isPagingEnabled()) {
            this.options.paging = true;
            this.options.pagingType = "simple_numbers";
            this.options.info = true;
        }

        this.bindServerProcessing();

        this.scanHeaders(true);
        this.bindTableEvents();
        this.grid = this.$table.DataTable(this.options as DataTables.Settings);
        this.isInitialized = true;
        this.onDrawCompleted();
        this.toolbar = new DataGridToolbar(this.getContainer());
    }

    getServerUrl() {
        const url = this.getContainer().data(DataGridConstants.Data.serverUrl);
        return url;
    }

    bindServerProcessing() {
        const self = this;
        const serverUrl = self.getServerUrl();
        if (!Helpers.isNullOrEmpty(serverUrl)) {
            this.options.processing = true;
            this.options.serverSide = true;
            this.options.ajax = {
                url: serverUrl,
                type: "POST",
                data(d) {
                    const dataArray = self.getContainer().closest("main").find("form").serializeArray();
                    // Grab form values containing user options
                    var form = {};
                    //$.each(dataArray, (i, field) => {
                    //    form[field.name] = field.value || "";
                    //});
                    // Add options used by Datatables
                    var info = (self.grid == null) ? { "start": 0, "length": self.pageLength() } : self.grid.page.info();
                    $.extend(form, info);
                    return JSON.stringify(form);
                }
            };
        }
    }

    /**
    * Get container of this data grid.
    */
    getContainer(): JQuery {
        return this.$table.closest(`div.${DataGridConstants.Css.container}`);
    }

    /**
     * Get toolbar of this data grid.
     */
    getToolbar(): DataGridToolbar {
        return this.toolbar;
    }

    /**Get pagination of this data grid. */
    getPagination(): JQuery {
        return this.getContainer().find(".dataTables_paginate");
    }

    /**Get footer of this data grid. */
    getFooter(): JQuery {
        return this.getContainer().find(".grid-footer");
    }

    /**
     * Indicate if the current data grid has paging set up.
     */
    isPagingEnabled() {
        return Helpers.getData<boolean>(this.$table, DataGridConstants.Data.enablePaging, true);
    }

    /*
     * Get number of records displaying on one page for this data grid.
     */
    pageLength() {
        return Helpers.getData<number>(this.$table, DataGridConstants.Data.pageLength, 100);
    }

    /**
     * Bind event when users click on the add button.
     * @param handler
     */
    onAdd(handler: (command: DataGridCommand) => any) {
        this.toolbar.bindCommand(DataGridConstants.Commands.add, handler);
    }

    /**
     * Bind event when users click on a button from the toolbar.
     * @param handler
     */
    onToolbarCommand(commandName:string, handler: (command: DataGridCommand) => any) {
        this.toolbar.bindCommand(commandName, handler);
    }

    /**
     * Bind event when users click on the edit button of a row.
     * @param handler
     */
    onRowEdit(handler: (command: DataGridCommand) => any) {
        this.bindRowCommand(DataGridConstants.Commands.edit, handler);
    }

    /**
    * Bind event when users click on the view button of a row.
    * @param handler
    */
    onRowView(handler: (command: DataGridCommand) => any) {
        this.bindRowCommand(DataGridConstants.Commands.view, handler);
    }

    /**
    * Bind event when users click on the delete button of a row.
    * @param handler
    */
    onRowDelete(handler: (command: DataGridCommand) => any) {
        this.bindRowCommand(DataGridConstants.Commands.delete, handler);
    }

    /**
    * Bind event when users click on the select button of a row.
    * @param handler
    */
    onRowSelect(handler: (command: DataGridCommand) => any) {
        this.bindRowCommand(DataGridConstants.Commands.select, handler);
    }

    /**
    * Bind event when users click on a custom button of a row.
    * @param handler
    */
    onRowCustomCommand(name: string, handler: (command: DataGridCommand) => any) {
        this.bindRowCommand(name, handler);
    }

    /**
     * Bind default event when users click on the delete button of a row.
     * @param onSuccess: Callback when success.
     * @param confirmMessageFunc: Message to show for the confirmation.
     */
    bindDefaultRowDelete(onSuccess?: () => any, confirmMessageFunc?: ($row: JQuery) => string) {
        const self = this;

        self.bindRowCommand(DataGridConstants.Commands.delete,
            (command) => {

                let message = DataGridConstants.Messages.deleteConfirmation;
                if (!Helpers.isNull(confirmMessageFunc)) {
                    message = confirmMessageFunc(command.parent());
                }

                self.basePage.showConfirmationModal(message,
                    () => {
                        command.ajaxPost(() => {
                            if (Helpers.isNull(onSuccess))
                                Helpers.reload();
                            else
                                onSuccess();
                        });
                    });
            });
    }

    getSelectedRows(): string[] {
        const list: string[] = [];
        this.rows.forEach((row) => {
            if (row.isSelected())
                list.push(row.getId());
        });

        return list;
    }

    getData() {
        return this.grid.rows().data();
    }

    addRow(row: any[] | object) {
        this.grid.row.add(row).draw();
    }

    removeRow(row: JQuery) {
        this.grid.row(row).remove();
        this.grid.draw();
    }

    getDataTable(): DataTables.Api {
        return this.grid;
    }

    getDataRow($row: JQuery): DataGridRow {
        const tableRow = this.grid.row($row);
        const gridRow = new DataGridRow($row, tableRow);
        return gridRow;
    }

    /**
     * Bind click event for a command in each row of the data grid.
     * @param name: Name of command to bind click event. 
     * @param handler
     */
    private bindRowCommand(name: string, handler: (command: DataGridCommand) => any) {
        const self = this;

        if (this.rowCommands.has(name)) {
            this.rowCommands.delete(name);
        }

        this.rowCommands.set(name, handler);

        self.rows.forEach((row) => {
            row.bindCommand(name, handler);
        });
    }

    /**
     * Bind events for datatable.
     */
    private bindTableEvents() {
        const self = this;

        // Fired once the table has completed a draw;.
        this.bindTableEvent(DataGridConstants.Events.draw,
            (e) => {
                self.onDrawCompleted();
                if (!Helpers.isNull(self.events.onDraw))
                    self.events.onDraw(e);
            });

        // Initialisation complete event - fired when DataTables has been fully initialised and data loaded.
        this.bindTableEvent(DataGridConstants.Events.init,
            (e) => {
                if (!Helpers.isNull(self.events.onInit))
                    self.events.onInit(e);
            });

        // Processing event - fired when DataTables is processing data.
        this.bindTableEvent(DataGridConstants.Events.processing,
            (e) => {
                if (!Helpers.isNull(self.events.onProcessing))
                    self.events.onProcessing(e);
            });
    }

    /**
     * Bind an event to the current data table.
     * @param eventName: Event name.
     * @param handler: Handler.
     */
    private bindTableEvent(eventName: string, handler: (e: JQueryEventObject) => any) {
        const self = this;
        self.$table.off(eventName)
            .on(eventName,
                (e: JQueryEventObject) => {
                    if (!Helpers.isNull(handler))
                        handler(e);
                });
    }

    /**
     * Bind events for rows.
     */
    private onDrawCompleted() {
        const self = this;
        if (Helpers.isNull(self.grid))
            return;

        self.rows = [];

        const data = self.grid.data();

        if (Helpers.isNull(data) || data.count() === 0) {
            Helpers.hide(self.getFooter());
            return;
        }

        self.$table.find("tbody tr")
            .each((index, row) => {
                const $row = $(row);
                const tableRow = self.grid.row($row);
                const gridRow = new DataGridRow($(row), tableRow);

                if (!Helpers.isNull(self.rowCommands)) {
                    self.rowCommands.forEach((handler: (command: DataGridCommand) => any, key: string) => {
                        gridRow.bindCommand(key, handler);
                    });
                }
                self.rows.push(gridRow);
            });

        // Hiding pagination if there is only one page.
        const pager = self.getPagination();
        if (Helpers.exists(pager)) {
            const items = pager.find(".page-item").not(".previous, .next, .first, .last");
            if (items.length <= 1)
                Helpers.hide(pager);
        }
    }

    /**
     * Configure layout for the current data table.
     * See DOM Position page for more details:
     * https://datatables.net/examples/basic_init/dom.html.
     */
    static getLayout(): string {

        const layout = `
            <'row ${DataGridConstants.Css.gridToolbar}'<'col-sm-12 col-md-4'f><'col-sm-12 col-md-8 ${DataGridConstants.Css.buttons}'>> 
            <'row ${DataGridConstants.Css.gridContent}'<'col-sm-12'tr>>
            <'row ${DataGridConstants.Css.gridFooter}'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>`;

        return layout;
    }

    /**
     * Scan setting attributes for each column.
     */
    private scanHeaders(scanSetting: boolean) {
        const notSearchable: number[] = [];
        const notSortable: number[] = [];
        const searchable: number[] = [];
        const self = this;

        self.headers = [];

        this.$table.find("> thead > tr > th")
            .each((index, element) => {
                const column = new DataGridColumn($(element));
                self.headers.push(column);

                if (column.isSubRowToggle())
                    self.bindToggleAllSubRow(column);

                self.bindSelectRowEvent(column);

                if (!scanSetting)
                    return;

                if (!column.isSearchable()) {
                    notSearchable.push(index);
                } else {
                    searchable.push(index);
                }

                if (!column.isSortable()) {
                    notSortable.push(index);
                }
            });

        if (searchable.length === 0 && notSortable.length === 0)
            return;

        this.options.columnDefs = [];

        if (searchable.length > 0) {
            this.options.columnDefs.push({ searchable: false, targets: notSearchable });
            this.options.searching = true;
        }

        if (notSortable.length > 0) {
            this.options.columnDefs.push({ orderable: false, targets: notSortable });
        }
    }

    /**
     * Bind event for toggling all sub rows.
     * @param column
     */
    private bindToggleAllSubRow(column: DataGridColumn) {
        if (Helpers.isNull(column))
            return;

        const self = this;
        const $toggle = column.element().find(".btn");
        if (!Helpers.exists($toggle))
            return;

        $toggle.off(DataGridConstants.Events.click)
            .on(DataGridConstants.Events.click, () => {
                const isShown = $toggle.attr(DataGridConstants.Attributes.ariaExpanded) === "true";

                self.rows.forEach((row) => {
                    row.showSubRow(!isShown);
                });

                $toggle.attr(DataGridConstants.Attributes.ariaExpanded, (!isShown).toString());
            });
    }

    /**
    * Bind event for toggling all sub rows.
    * @param column
    */
    private bindSelectRowEvent(column: DataGridColumn) {
        if (Helpers.isNull(column) || !column.element().hasClass(DataGridConstants.Css.rowSelectCheckbox))
            return;

        const self = this;
        const $checkbox = column.element().find("input[type='checkbox']");
        if (!Helpers.exists($checkbox))
            return;

        $checkbox.off("change")
            .on("change", () => {
                const isChecked = $checkbox.is(":checked");

                self.rows.forEach((row) => {
                    row.bindSelectRow(isChecked);
                });
            });
    }
}