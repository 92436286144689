import { PopoverOption } from "bootstrap";

/**
 * Helpers for client side.
 */
export class Helpers {

    static readonly emptyGuid = "00000000-0000-0000-0000-000000000000";

    /**
     * Reload a page.
     */
    static reload(removeQuery: boolean = false) {
        let url = location.href;
        const hashIndex = url.indexOf("#");
        if (hashIndex > 0)
            url = url.substring(0, hashIndex);

        if (removeQuery) {
            if (url.indexOf("?") > 0) {
                url = url.substring(0, url.indexOf("?"));
            }
        }

        location.href = url;
    }

    /**
     * Check if a value is null or undefined.
     * @param value: Value to check.
     */
    static isNull(value: any): boolean {
        return value === null || value === undefined;
    }

    /**
    * Check if a value is null or undefined.
    * @param value: Value to check.
    */
    static isNullOrEmpty(value: any): boolean {
        return value === null || value === undefined || value === "" || value === Helpers.emptyGuid;
    }

    /**
     * Check if an element exists.
     * @param $element: element to check.
     */
    static exists($element: JQuery): boolean {
        if (Helpers.isNull($element))
            return false;

        return $element.length > 0;
    }

    /**
     * Get content for generate a spinner.
     */
    static getSpinner(): string {
        return "<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>";
    }

    /**
     * Disable a button for loading.
     * @param $button: Button.
     */
    static lockButton($button: JQuery) {
        if (!Helpers.exists($button))
            return;

        const text = $button.html();
        $button.data("text", text);
        $button.html(text + "&nbsp;&nbsp;" + Helpers.getSpinner());
        $button.prop("disabled", true);
    }

    /**
     * Enable a button.
     * @param $button: Button.
     */
    static unlockButton($button: JQuery) {
        if (!Helpers.exists($button))
            return;

        const text = $button.data("text");

        // This button wasn't locked by the "lockButton" method, exist.
        if (Helpers.isNull(text))
            return;

        $button.removeData("text");
        $button.html(text);
        $button.prop("disabled", false);
    }

    /**
     * Copy a specific text to the clipboard.
     * @param text: Text to copy to the clipboard.
     */
    static copyTextToClipboard(text: string) {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;

        // This is for prevent scrolling to bottom of page in MS Edge.
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");

        document.body.removeChild(textarea);
    }

    /**
     * Create a bootstrap popover to display as tooltip.
     * @param $element: Element to trigger the popover.
     * @param content: Content to display on the popover.
     */
    static createPopover($element: JQuery, content: string) {
        if (!Helpers.exists($element))
            return;

        const popoverOptions: PopoverOption = {
            container: "body",
            trigger: "manual",
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
        };

        $element.data("toggle", "popover");
        $element.data("content", content);

        $element.popover(popoverOptions);
        $element.prop("title", $element.data("original-title"));
    }

    /**
     * Trigger click event on an element.
     * @param $element: Element to trigger click event.
     */
    static triggerClick($element: JQuery) {
        if (!Helpers.exists($element))
            return;

        $element.trigger("click");
    }

    /**
     * Bind click event for an element and perform ajax GET when it is clicked.
     * @param $element: Element to bind click event.
     * @param url: URL for ajax get.
     * @param data: Data.
     * @param onSuccess: Callback when success.
     */
    static performAjaxGetOnClick($element: JQuery, url: string, data: any, onSuccess: (result: any) => any) {
        if (!Helpers.exists($element))
            return;

        $element.off("click")
            .on("click",
                () => {
                    $.ajax(
                        {
                            url: url,
                            data: data,
                            type: "GET",
                            success(result) {
                                if (!Helpers.isNull(onSuccess))
                                    onSuccess(result);
                            }
                        });
                });
    }

    /**
    * Bind click event for an element and perform ajax POST when it is clicked.
    * @param $element: Element to bind click event.
    * @param url: URL for ajax get.
    * @param data: Data.
    * @param onSuccess: Callback when success.
    */
    static performAjaxPostOnClick($element: JQuery, url: string, data: any, onSuccess: (result: any) => any) {
        if (!Helpers.exists($element))
            return;

        $element.off("click")
            .on("click",
                () => {
                    $.ajax(
                        {
                            url: url,
                            data: data,
                            type: "POST",
                            success(result) {
                                if (!Helpers.isNull(onSuccess))
                                    onSuccess(result);
                            }
                        });
                });
    }

    /**
    * Get value of a data attribute and convert it to a desired type.
    * @param $item: Item to get data attribute.
    * @param name: Name of data attribute without data- prefix.
    * @param defaultValue: Default value if the data does not exist or does not have value.
    */
    static getData<T>($item: JQuery, name: string, defaultValue: T): T {

        // Item does not exist, return default value.
        if (!Helpers.exists($item))
            return defaultValue;

        const data = $item.data(name);
        if (Helpers.isNull(data))
            return defaultValue;

        const value = data as T;

        return value;
    }

    /**
   * Get value of a data attribute and convert it to a desired type.
   * @param $item: Item to get data attribute.
   * @param defaultValue: Default value if the data does not exist or does not have value.
   */
    static getValue<T>($item: JQuery, defaultValue: T): T {

        // Item does not exist, return default value.
        if (!Helpers.exists($item))
            return defaultValue;

        const data = $item.val() as any;
        if (Helpers.isNull(data))
            return defaultValue;

        const value = data as T;

        return value;
    }

    /**
     * Join non-empty string together by a separator.
     * @param separator
     * @param items
     */
    static joinNonEmpty(separator: string, items: string[]): string {
        if (Helpers.isNull(items))
            return null;
        const nonEmpty = items.filter((value) => {
            return !Helpers.isNull(value) && value.trim() !== "";
        });
        const result = nonEmpty.join(separator);

        return result;
    }

    /**
     * Disable an element.
     * @param $element: Element to be disabled.
     */
    static disable($element: JQuery) {
        if(!Helpers.exists($element))
            return;

        $element.prop("disabled", true);
    }

    /**
    * Check if an element is disabled.
    * @param $element: Element to check.
    */
    static isDisable($element: JQuery) : boolean {
        if (!Helpers.exists($element))
            return false;

        return $element.is(":disabled");
    }

    /**
    * Check if an element is readonly.
    * @param $element: Element to check.
    */
    static isReadOnly($element: JQuery): boolean {
        if (!Helpers.exists($element))
            return false;

        let value = $element.attr("readonly");
        if (Helpers.isNull(value))
            return false;

        value = value.toLowerCase();
        if(value === "readonly" || value === "true")
            return true;
        return false;
    }

    /**
    * Enable an element.
    * @param $element: Element to be enabled.
    */
    static enable($element: JQuery) {
        if (!Helpers.exists($element))
            return;

        $element.prop("disabled", false);
    }

    /**
     *  Remove the d-none class for an element to show it.
     * @param $element
     */
    static show($element: JQuery) {
        if (!Helpers.exists($element))
            return;

        $element.removeClass("d-none");
    }

    /**
     *  Add the d-none class for an element to hide it.
     * @param $element
     */
    static hide($element: JQuery) {
        if (!Helpers.exists($element))
            return;

        $element.addClass("d-none");
    }

    /**
     * Reset a dropdown (select the first option).
     * @param $element
     */
    static resetDropDown($element: JQuery) {
        if (!Helpers.exists($element))
            return;

        const firstValue = $element.find("option:first").val();
        $element.val(firstValue);
    }

    static combine(separator: string, list: any[]) {
        if (Helpers.isNull(list))
            return "";
        const result = list.join(separator);
        return result;
    }
}