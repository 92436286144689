/**
 * Contains list of data grid events that needs to be set up when creating an instance of data grid.
 */
export class DataGridEvents {
    /**
     * Draw event - fired once the table has completed a draw.
     */
    onDraw?: (e: JQueryEventObject) => any;

    /**
     * Initialization complete event - fired when DataTables has been fully initialized and data loaded.
     */
    onInit?: (e: JQueryEventObject) => any;

    /**
     * Processing event - fired when DataTables is processing data.
     */
    onProcessing?: (e: JQueryEventObject) => any;
}