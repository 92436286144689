
/**
 * Contains constants used for data grid.
 */
export namespace DataGridConstants {

    /**
     * List of all CSS names used for data grid.
     */
    export class Css {
        static readonly gridCommand = "grid-command";
        static readonly gridToolbar = "grid-toolbar";
        static readonly container = "data-grid";
        static readonly subRowToggle = "sub-row-toggle";
        static readonly subRowColumn = "sub-row-column";
        static readonly shown = "shown";
        static readonly rowDetail = "row-detail";
        static readonly noToolbar = "no-toolbar";
        static readonly buttonRow = "button-row";
        static readonly buttons = "buttons";
        static readonly gridContent = "grid-content";
        static readonly gridFooter = "grid-footer";
        static readonly truncatedColumn = "truncated-column";
        static readonly truncatedText = "truncated-text";
        static readonly truncatedTextWithCopy = "truncated-text-with-copy";
        static readonly fullText = "full-text";
        static readonly showFullText = "show-full-text";
        static readonly truncatedDot = "truncated-dots";
        static readonly rowSelectCheckbox = "row-select-checkbox";
    }

    /**
     * List of all built-in commands used for data grid.
     */
    export class Commands {
        static readonly edit = "edit";
        static readonly delete = "delete";
        static readonly add = "add";
        static readonly view = "view";
        static readonly select = "select";
        static readonly copy = "copy";
    }

    /**
     * List of all names for HTML data attribute used for data grid.
     */
    export class Data {
        static readonly id = "id";
        static readonly url = "url";
        static readonly enableSearch = "enable-search";
        static readonly enablePaging = "enable-paging";
        static readonly enableSorting = "enable-sorting";
        static readonly commandName = "command-name";
        static readonly pageLength = "page-length";
        static readonly serverUrl = "server-url";
    }

    /**
     * List of all HTML attribute names used for data grid.
     */
    export class Attributes {
        static readonly ariaExpanded = "aria-expanded";
    }

    /**
     * List of all messages used for data grid.
     */
    export class Messages {
        static readonly deleteConfirmation = "Are you sure you want to delete this item?";
        static readonly unableToPerformCommand = "Unable to perform this action.";
        static readonly searchLabel = "Search: ";
        static readonly pagingInfo = "<b>_START_-_END_</b> of <b>_TOTAL_</b>";
        static readonly more = "more";
        static readonly less = "less";
    }

    /**
     * List of all data grid events.
     */
    export class Events {
        static readonly draw = "draw.dt";
        static readonly init = "init.dt";
        static readonly processing = "processing.dt";
        static readonly click = "click.command";
    }
}