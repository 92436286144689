import { Helpers } from "./Helpers";

/**
 * Helpers for form validation.
 */
export class ValidationHelpers {

    /**
     * Default CSS error for a field.
     */
    static errorCss = "input-validation-error";
    static summaryErrors = "validation-summary-errors";
    static summaryValid = "validation-summary-valid";

    /**
    * Register validation for a form.
    * @param $form: Form to register validation.
    */
    static registerValidation($form: JQuery): void {
        if (!Helpers.exists($form))
            return;

        $form.removeData("validator")
            .removeData("unobtrusiveValidation");
        $.validator.unobtrusive.parse($form);
    }

    /**
     * Remove error message(s) from the validation summary and hide the validation summary.
     * @param $form
     */
    static resetValidationSummary($form: JQuery) {
        if (!Helpers.exists($form))
            return;

        const $validation = $form.find("[data-valmsg-summary=true]");
        if ($validation) {
            $validation.find("ul").empty();
            $validation.removeClass(ValidationHelpers.summaryErrors).addClass(ValidationHelpers.summaryValid);
        }
    }

    /**
     * Check if a form is valid.
     * @param $form
     */
    static isValid($form: JQuery): boolean {
        if (!Helpers.exists($form))
            return true;

        return $form.valid();
    }

    /**
     * Reset validation for a form.
     * @param $form: The form to reset validation.
     */
    static resetFormValidation($form: JQuery) {
        if (!Helpers.exists($form))
            return;

        // Reset form validation.
        $form.validate().resetForm();

        // Remove/Hide validation summary error.
        ValidationHelpers.resetValidationSummary($form);

        // Remove all red highlight from fields.
        $form.find(`.${ValidationHelpers.errorCss}`).removeClass(ValidationHelpers.errorCss);
    }

    /**
    * Append errors to validation summary and show it.
    * @param $form
    * @param $error
    */
    static setValidationSummaryErrors($form: JQuery, $error: Array<string>) {
        if (!Helpers.exists($form))
            return;

        const $validation = $form.find(".validation-summary");
        const $list = $validation.find("ul").empty();
        if ($error && $validation) {
            $validation.addClass(ValidationHelpers.summaryErrors).removeClass(ValidationHelpers.summaryValid);
            $.each($error, (i, e) => { $list.append($("<li>").text(e)); });
        }
    }

    /**
     * Check if a form has validation error or not.
     * @param $form
     */
    static hasError($form: JQuery): boolean {
        if (!Helpers.exists($form))
            return false;

        const errorCount = $form.find(`.${ValidationHelpers.summaryErrors} ul li`).length;
        return errorCount > 0;
    }
}