﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class ClientUserLoginHistory {

    // Client Search page.
    private clientDataGrid: DataGrid;
    private $clientSearchGrid: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor() {

        // Get controls for the Client search page.
        this.$clientSearchGrid = $("#gridData");
      
        // Bind form events.
        this.bindSearchPage();
    }

    /**
     * Set up for the Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$clientSearchGrid))
            return;

        // Data table.
        self.clientDataGrid = new DataGrid(self.$clientSearchGrid);
    }
}