﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";
import { BootstrapModal } from "../../../Shared/CustomControls/BootstrapModal";
import { DataGridCommand } from "../../../Shared/CustomControls/DataGrids/DataGridCommand";

export class ClientAppInvites {

    // Client Search page.
    private dataGrid: DataGrid;
    private $dataGrid: JQuery;
    private editModal: BootstrapModal;

    /**
    * Create a new instance of a page.
    */
    constructor() {

        // Get controls for the Client search page.
        this.$dataGrid = $("#gridData");
      
        // Bind form events.
        this.bindSearchPage();
        this.bindModal();
    }

    /**
     * Set up for the Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$dataGrid))
            return;

        // Data table.
        self.dataGrid = new DataGrid(self.$dataGrid);
        self.dataGrid.onRowView(
            (command: DataGridCommand) => {
                const url = command.url();
                this.editModal.loadAndOpen(url);
            });
    }

    /**
    * Set up modal for viewing details of a log.
    */
    private bindModal() {
        const self = this;
        self.editModal = new BootstrapModal($("#editModal"));
    }
}