import { DataGridConstants } from "./DataGridConstants";
import { DataGridCommand } from "./DataGridCommand";
import { Helpers } from "../../Utilities/Helpers";

/**
 * Contains or processes data for a row of a data grid.
 */
export class DataGridRow {

    private commands: Map<string, DataGridCommand>;

    /**
     * Create a new instance of a row.
     * @param $row: HTML element.
     * @param gridRow: Row data from datatable net.
     */
    constructor(private $row: JQuery, private gridRow?: DataTables.RowMethods) {

        this.commands = new Map();
        this.scanCommands();
        this.scanSubRowToggle();
        this.scanTruncatedText();
    }

    /**
     * Bind event to a command of the current row.
     * @param name: Name of command.
     * @param handler: Handler to be executed when a command is clicked.
     */
    bindCommand(name: string, handler: (command: DataGridCommand) => any) {
        if (!this.commands.has(name)) {
            return;
        }

        let command = this.commands.get(name);
        command.onClick((e) => handler(e));
    }

    /**
     * HTML element represent the row.
     */
    element(): JQuery {
        return this.$row;
    }

    getId(): string {
        return this.$row.attr("id");
    }

    /**
     * Look for HTML element of a column by name.
     * @param name
     */
    column(name: string):JQuery {
        return this.$row.find(`td[data-name='${name}']`);
    }

    /**
     * Show or hide sub row.
     * @param isShown: True to show. False to hide.
     */
    showSubRow(isShown: boolean) {
        const isSubRowShowing = this.gridRow.child.isShown();
        const $toggle = this.getSubRowToggle();

        // Showing sub row.
        if (isShown && !isSubRowShowing || !isShown && isSubRowShowing) {
            this.toggleSubRow();
            this.setSubRowToggleState($toggle, isShown);
            return;
        }
    }

    /**
    * Action triggered when the sub row toggle is clicked. This will show/hide sub row.
    */
    toggleSubRow(): boolean {
        const $subRowColumn = this.$row.find(`td.${DataGridConstants.Css.subRowColumn}`);

        if (!Helpers.exists($subRowColumn))
            return false;

        const content = $subRowColumn.html();

        if (this.gridRow.child.isShown()) {
            this.gridRow.child.hide();
            this.$row.removeClass(DataGridConstants.Css.shown);
            return false;
        }

        this.gridRow.child(content, DataGridConstants.Css.rowDetail).show();
        this.$row.addClass(DataGridConstants.Css.shown);
        return true;
    }

    bindSelectRow(isSelected: boolean) {
        const $checkbox = this.getSelectCheckbox();
        if (!Helpers.exists($checkbox))
            return;

        $checkbox.prop("checked", isSelected);
    }

    isSelected(): boolean {
        const $checkbox = this.getSelectCheckbox();
        if (!Helpers.exists($checkbox))
            return false;

        return $checkbox.is(":checked");
    }

    private getSelectCheckbox(): JQuery {
        const $checkbox = this.$row.find(`td.${DataGridConstants.Css.rowSelectCheckbox} input[type='checkbox']`);
        return $checkbox;
    }

    /**
     * Scan all commands of a row.
     */
    private scanCommands() {
        const self = this;

        self.$row.find(`td .${DataGridConstants.Css.gridCommand}`).each((index, button) => {
            const $button = $(button);
            const command = new DataGridCommand($button, self.$row);
            this.commands.set(command.commandName(), command);
            Helpers.show($button);
        });
    }

    /**
     * Scan all the sub row toggle in the current data grid and bind the click event.
     */
    private scanSubRowToggle() {
        const self = this;
        const $toggle = self.getSubRowToggle();
        if (!Helpers.exists($toggle) || Helpers.isNull(self.gridRow))
            return;

        $toggle.off(DataGridConstants.Events.click).on(DataGridConstants.Events.click,
            () => {
                const isShown = self.toggleSubRow();
                self.setSubRowToggleState($toggle, isShown);
            });
    }

    /**
     * Set state for sub row toggle.
     * @param $toggle: Toggle.
     * @param isShown: True if sub row is shown.
     */
    private setSubRowToggleState($toggle: JQuery, isShown:boolean) {
        if (isShown)
            $toggle.attr(DataGridConstants.Attributes.ariaExpanded, "true");
        else
            $toggle.attr(DataGridConstants.Attributes.ariaExpanded, "false");
    }

    /**
     * Get the button to toggle sub row.
     */
    private getSubRowToggle() {
        const $toggle = this.$row.find(`td.${DataGridConstants.Css.subRowToggle} a`);

        return $toggle;
    }

    /**
     * Scan truncated text.
     */
    private scanTruncatedText() {
        const self = this;

        self.$row.find(`td.${DataGridConstants.Css.truncatedColumn}`)
            .each((index, cell) => {
                const $cell = $(cell);
                const $remaining = $cell.find(`.${DataGridConstants.Css.fullText}`);
                const $dot = $cell.find(`.${DataGridConstants.Css.truncatedDot}`);
                const $toggle = $cell.find("button");
                $remaining.hide();

                $toggle.off(DataGridConstants.Events.click)
                    .on(DataGridConstants.Events.click, () => {
                        if ($remaining.is(":visible")) {
                            // Hiding full text.
                            $dot.show();
                            $remaining.hide();
                            $toggle.html(DataGridConstants.Messages.more);
                            return;
                        }

                        // Showing full text.
                        $dot.hide();
                        $remaining.show();
                        $toggle.html(DataGridConstants.Messages.less);
                    });
            });

    }
}