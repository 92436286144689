﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class User {

    private dataGrid: DataGrid;
    private $grid: JQuery;
    private $passwordComplexLevel: JQuery;
    private $searchButton: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");
        this.$passwordComplexLevel = $("#PasswordComplexLevel");
        this.$searchButton = $("#btnSearch");

        this.bindSearchPage();
        this.bindEditPage();
    }

    /**
     * Set up for the Target Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;

        // Data table.
        self.dataGrid = new DataGrid(self.$grid);
        self.dataGrid.bindDefaultRowDelete(() => { Helpers.triggerClick(self.$searchButton);}, () => "Are you sure you want to delete this user?");
    }

    /**
     * Set up for the user edit page.
     */
    private bindEditPage() {
        const self = this;

        if (Helpers.exists(self.$grid))
            return;

        self.bindComplexPassword();
    }

    /**
     * Set up for displaying note for password complex.
     */
    private bindComplexPassword() {
        const self = this;
        const level = Number(self.$passwordComplexLevel.val());
        $(".password-complex-note").each((index, element) => {
            const $element = $(element);
            const elementLevel = Number($element.data("level"));
            if (elementLevel === level)
                Helpers.show($element);
            else
                Helpers.hide($element);
        });
    }
}