﻿import { DataGrid } from "../../../Shared/CustomControls/DataGrids/DataGrid";
import { Helpers } from "../../../Shared/Utilities/Helpers";

export class ClientChangeNameRequest {

    private dataGrid: DataGrid;
    private $grid: JQuery;

    /**
    * Create a new instance of a page.
    */
    constructor() {
        this.$grid = $("#gridData");
        this.bindSearchPage();
    }

    /**
     * Set up for the Search page.
     */
    private bindSearchPage() {
        const self = this;

        if (!Helpers.exists(self.$grid))
            return;
        
        // Data table.
        self.dataGrid = new DataGrid(self.$grid);
    }
}